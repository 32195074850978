
export default {
	name: 'SlaCreatePage',
	props: {
		editData: {
			type: Object,
			default: null
		}
	},
	data() {
		const numberExp = /^([1-9][0-9]*)$/
		const feedbacktimeValidator = (rule, value, cb) => {
			if (value) {
				if (!numberExp.test(value)) {
					cb(new Error(this.$t('sla.numberTips')))
				} else if (value > 32000) {
					cb(new Error(this.$t('sla.slaMaximumTips')))
				} else {
					cb()
				}
			} else {
				cb(new Error(this.$t('sla.slaEmptyTips')))
			}
		}
		return {
			// 表单数据
			submitData: {
				description: '',
				feedbacktime: ''
			},
			// 校验规则
			submitRules: {
				description: [{required: true, message: this.$t('sla.slaEmptyTips'), trigger: 'blur'},
					{max: 50, message: this.$t('sla.slaExceedTips'), trigger: 'blur'},],
				feedbacktime: [{ required: true, validator: feedbacktimeValidator, trigger: 'blur' }]
			},
			reasonTypeList: []
		}
	},
	created() {
		if (this.editData) {

			this.submitData = JSON.parse(JSON.stringify(this.editData))
		}

	},
	methods: {
		// 提交
		submitForm(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					if (this.editData) {
						// 修改数据
						const requsetEditJson = {
							hrid: this.$store.getters.loginid,
							penum: this.$store.getters.penum,
							id: this.submitData.id,
							"description": this.submitData.description,
							"feedbacktime": this.submitData.feedbacktime,

						}
						this.$http.post('/v2/common/slapriority/edit', requsetEditJson).then(response => {
							if (response.data.success) {
								this.$message({
									type: 'success',
									message: this.$t('sla.editSuccess')
								});
								this.$emit('createSuccess')
							} else {
								this.$alert(response.data.message, this.$t('common.alertPrompt'), {
									type: 'error',
									center: true
								})
							}
						})
					} else {

						const requsetJson = {
							hrid: this.$store.getters.loginid,
							"description": this.submitData.description,
							"feedbacktime": Number(this.submitData.feedbacktime),
						}
						this.$http.post('/v2/common/slapriority/add', requsetJson).then(response => {
							if (response.data.success) {

								this.$message({
									type: 'success',
									message: this.$t('sla.createSuccess')
								});
								this.$emit('createSuccess')
							} else {
								this.$alert(response.data.message, this.$t('common.alertPrompt'), {
									type: 'error',
									center: true
								})
							}
						})
					}

				} else {
					// console.log('error submit!!');
					return false;
				}
			});
		},
		// 关闭
		closeDialog() {
			this.$emit('closeDialog')
		},
	}
}